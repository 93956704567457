<script
    lang="ts"
    setup
>
    import type { RouteLocationNamedRaw } from 'vue-router'

    type Props = {
        to: string | RouteLocationNamedRaw
        target?: string
        replace?: boolean
        external?: boolean
        disabled?: boolean
        disabledOnlyColor?: boolean
        active?: boolean
        wrapper?: boolean
        invert?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        target: undefined,
        replace: undefined,
        external: undefined,
        disabled: false,
        disabledOnlyColor: false,
        active: false,
        wrapper: false,
        invert: false,
    })

    const style = useCssModule()

    const linkClass = computed<string[]>(() => {
        if (props.wrapper) {
            return [ style['link-wrapper'] ]
        }

        const classes = [ style['link'] ]

        if (props.active) {
            classes.push(style['link--active'])
        }
        if (props.invert) {
            classes.push(style['link--invert'])
        }

        if (props.disabled) {
            classes.push(style['link--disabled'])
        }

        if (props.disabledOnlyColor) {
            classes.push(style['link--disabled-only-color'])
        }

        return classes
    })
</script>

<template>
    <NuxtLink
        key="link"
        :class="linkClass"
        :to="props.disabled ? null : props.to"
        :target="props.target"
        :replace="props.replace"
        :external="props.external"
    >
        <slot />
    </NuxtLink>
</template>

<style
    lang="sass"
    module
    scoped
>
    .link-wrapper
        appearance: none
        text-decoration: none
        color: currentColor
        cursor: pointer

    .link
        --link-color-default: #000
        --link-color-active: #8a8f9e

        appearance: none
        display: flex
        flex-direction: column
        justify-content: center
        align-items: flex-start
        padding: 0
        font-style: normal
        font-size: 14px
        font-weight: 400
        line-height: 130%
        text-align: center
        text-decoration: underline
        text-underline-offset: 2px
        outline: none
        border: none
        background: none
        color: var(--link-color-default)
        transition: color var(--transition-default-duration-with-ease)
        will-change: color
        cursor: pointer

        &--invert
            --link-color-default: #8a8f9e
            --link-color-active: #000

        &:not(&--disabled):not(&--disabled-only-color):hover,
        &:active,
        &--active
            color: var(--link-color-active)

        &--disabled,
        &--disabled-only-color
            color: #a7a7a7

        &--disabled
            cursor: not-allowed
</style>
